import { render, staticRenderFns } from "./TheFooterES.vue?vue&type=template&id=2eec251b&lang=pug"
import script from "./TheFooterES.vue?vue&type=script&lang=ts"
export * from "./TheFooterES.vue?vue&type=script&lang=ts"
import style0 from "./TheFooterES.vue?vue&type=style&index=0&id=2eec251b&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Trustpilot: require('/app/components/generic/Trustpilot.vue').default,PhoneNumber: require('/app/components/generic/PhoneNumber.vue').default})
