
import Vue from "vue"
import { extractImageUrl, getInnerText } from "~/scripts/useful-functions"

export default Vue.extend({
  name: "OtherServicesBlock",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    }
  },
  data() {
    return {
      imageUrl: "" as string | null,
      headingText: "",
      descriptionText: "",
      readMoreText: "",
      readMoreUrl: "",
      hover: false
    }
  },
  created() {
    this.imageUrl = extractImageUrl(this.block.innerBlocks[0].innerHtml)
    this.headingText = getInnerText(this.block.innerBlocks[1].innerHtml)
    this.descriptionText = getInnerText(this.block.innerBlocks[2].innerHtml)
    this.readMoreText = getInnerText(this.block.innerBlocks[3].innerHtml)
    this.readMoreUrl = getInnerText(this.block.innerBlocks[4].innerHtml)
  },
  methods: {
    handleEnter() {
      this.hover = true
    },
    handleLeave() {
      this.hover = false
    }
  }
})
