import { render, staticRenderFns } from "./AvFaqs.vue?vue&type=template&id=0e9d2712&lang=pug"
import script from "./AvFaqs.vue?vue&type=script&lang=js"
export * from "./AvFaqs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AvAccordion: require('/app/components/generic/AvAccordion.vue').default,Block: require('/app/components/generic/Block.vue').default})
